// --- Custom Login Page -- //

// --- Variables and Mixins --- //
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

@import '../base/core/variables';
@import '../base/core/mixins';
@import '../base/core/icons';
@import '../base/core/colors';
@import '../base/core/css_colors';
@import '../base/core/colors_extra_mapping';
@import '../base/core/type';

// --- Login Style --- //
body {
  &.login {
    background-color: $gray-lighter;
    font-family: $font-family-base;
    display: flex;
    justify-content: center;
    align-items: center;
    * {
      box-shadow: none !important;
      transition: all 0.2s ease-in;
      &:focus,
      &:hover {
        box-shadow: none !important;
        transition: all 0.2s;
      }
    }
    input {
      &:focus,
      &:hover {
        border-color: $brand-primary;
        box-shadow: none !important;
        transition: all 0.2s;
      }
    }
    #login {
      padding: 0;
      background-color: transparent;
      h1 {
        width: 100%;
        background-color: transparent;
        a {
          background: transparent url('../img/login__logo.svg') no-repeat top left;
          background-size: auto 100%;
          width: 370px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      p {
        color: $gray-base;
        background: $brand-white;
        a {
          color: $gray-base;
          &:hover {
            color: $brand-secondary;
          }
        }
      }
      label {
        color: $gray-base;
        background: $brand-white;
      }
      .message {
        background: $brand-white;
        border-left-color: $gray-light;
        box-shadow: none;
        margin: 50px 0 0 0px;
      }
      #loginform {
        border: none;
        box-shadow: none;
        margin: 50px 0 0 0px;
        padding: 20px 24px 12px;
        background-color: $brand-white;
        input {
          font-size: 14px;
          padding: 10px;
          margin: 4px 0 12px;
          @include placeholder {
            color: $gray-base;
            opacity: 0.25;
          }
        }
        #wp-submit {
          background-color: $brand-secondary;
          color: $brand-white;
          border: none;
          box-shadow: none;
          font-size: 18px;
          height: auto;
          font-weight: $fw-regular;
          text-shadow: none;
          border-radius: 0px;
          padding: 8px 24px;
          width: 100%;
          &:hover {
            // color: $brand-primary;
          }
        }
        .forgetmenot {
          label {
            display: flex;
            align-items: center;
          }
          input {
            margin: 9px 8px 9px 0;
          }
        }
        input[type='checkbox']:checked:before {
          color: $gray-dark;
        }
      }
      #login_error {
        background: $brand-white;
        border-left-color: $gray-light;
        box-shadow: none;
        color: $gray-base;
        margin: 50px 0 0 0px;
      }
      .privacy-policy-page-link {
        display: none;
      }
      #nav,
      #backtoblog {
        background: transparent;
        a {
          background: transparent;
          &:hover {
            color: $brand-primary;
          }
        }
      }
    }
  }
}
