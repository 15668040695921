// --- Color Mapping Magic --- //
// C:/laragon/www/churchill/app/themes/carbonate/assets/
:root {
  // Loop over each field in the primary base (used as default when no class added to the body)
  @each $field, $color-swatch in map-get($theme-palette, brand-base) {
    --theme-#{$field}: #{$color-swatch};
  }
  --theme-banner-color: #{$brand-banner};
  --theme-home-slider-gradient: linear-gradient(
    88.78deg,
    rgba(41, 55, 141, 0.75) 0%,
    rgba(24, 152, 139, 0.58) 100%
  );
  --theme-green: #{$brand-primary};
  --theme-blue: #{$brand-secondary};
  --theme-red: #{$brand-tertiary};
  --theme-yellow: #{$brand-quaternary};
}
// Loop over each theme-palette
@each $color, $color-swatch in $theme-palette {
  .#{$color} {
    // Loop over each field
    @each $field, $color-swatch in map-get($theme-palette, #{$color}) {
      --theme-#{$field}: #{$color-swatch};
    }
  }
}

// base styles
#wrapper-animation,
footer,
section,
.scene_element,
section div {
  background-color: var(--theme-color);
  color: var(--theme-font);
  // * {
  //   background-color: var(--theme-color);
  //   color: var(--theme-font);
  // }
}
