// // --- Colors --- //

// Project Brand colours //
$brand-primary: #089981;
$brand-secondary: #29368d;
$brand-tertiary: #ef4145;
$brand-quaternary: #eabd29;
$brand-quinary: #fefcf9;
$brand-senary: #310772;

$brand-banner: #b6ded8;

$pill-primary: #089982;
$pill-secondary: #29368e;
$pill-tertiary: #ef4146;
$pill-quaternary: #eabd30;

// background-color: rgba(3,152,128,1);

$brand-white: #ffffff;
$brand-black: #000000;

$gray-lighter: #f3f5f8;
$gray-light: #c2c2c2;
$gray-base: #818181;
$gray-dark: #6f6f6f;
$gray-darker: #25292b;
$gray-darkest: #191919;

$error: #ef4145;

// Color map //
$theme-palette: (
  brand-base: (
    color: $brand-white,
    font: $brand-secondary,
    accent: $brand-secondary,
    accent-font: $brand-primary,
    btn-color: $brand-white,
    btn-background: $brand-secondary,
    btn-border: $brand-secondary,
    form-label: $gray-base,
    form-label-active: $brand-secondary,
    form-background: $brand-white,
    form-border: $gray-base,
    form-placeholder:
      transparentize(
        $color: $gray-base,
        $amount: 0.5,
      ),
    icon-color: $brand-primary,
    icon-color-hover: $brand-secondary,
  ),
  brand-primary: (
    color: $brand-primary,
    font: $brand-white,
    accent: $brand-secondary,
    accent-font: $brand-primary,
    btn-color: $brand-white,
    btn-background: $brand-secondary,
    btn-border: $brand-secondary,
    form-label: $gray-base,
    form-label-active: $brand-secondary,
    form-background: $brand-white,
    form-border: $brand-white,
    form-placeholder:
      transparentize(
        $color: $brand-white,
        $amount: 0.5,
      ),
    icon-color: $brand-primary,
    icon-color-hover: $brand-secondary,
  ),
  brand-secondary: (
    color: $brand-secondary,
    font: $brand-white,
    accent: $brand-quaternary,
    accent-font: $brand-primary,
    btn-color: $brand-white,
    btn-background: $brand-secondary,
    btn-border: $brand-secondary,
    form-label: $gray-base,
    form-label-active: $brand-secondary,
    form-border: $gray-base,
    form-placeholder:
      transparentize(
        $color: $brand-white,
        $amount: 0.5,
      ),
    icon-color: $brand-white,
    icon-color-hover: $brand-quaternary,
  ),
  brand-tertiary: (
    color: $brand-tertiary,
    font: $brand-white,
    accent: $brand-primary,
    accent-font: $brand-secondary,
    btn-color: $brand-white,
    btn-background: $brand-tertiary,
    btn-border: $brand-white,
    form-label: $brand-black,
    form-label-active: $brand-secondary,
    form-border: $brand-black,
    form-placeholder:
      transparentize(
        $color: $brand-black,
        $amount: 0.5,
      ),
    icon-color: $brand-primary,
    icon-color-hover: $brand-secondary,
  ),
  brand-quaternary: (
    color: $brand-quaternary,
    font: $brand-white,
    accent: $brand-black,
    accent-font: $gray-light,
    btn-color: $brand-black,
    btn-background: $brand-quaternary,
    btn-border: $brand-quaternary,
    form-label: $gray-base,
    form-label-active: $brand-secondary,
    form-background: $brand-white,
    form-border: $gray-base,
    form-placeholder: $gray-base,
    icon-color: $brand-primary,
    icon-color-hover: $brand-secondary,
  ),
  brand-white: (
    color: $brand-white,
    font: $gray-darker,
    accent: $brand-secondary,
    accent-font: $brand-primary,
    btn-color: $brand-white,
    btn-background: $brand-primary,
    btn-border: $brand-primary,
    form-label: $gray-darker,
    form-label-active: $brand-primary,
    form-background: $brand-white,
    form-border: $gray-darker,
    form-placeholder: $gray-base,
    icon-color: $brand-secondary,
    icon-color-hover: $brand-primary,
  ),
  brand-black: (
    color: $brand-black,
    font: $brand-white,
    accent: $gray-base,
    accent-font: $gray-light,
    btn-color: $brand-black,
    btn-background: $brand-white,
    btn-border: $brand-white,
    form-label: $gray-base,
    form-label-active: $brand-secondary,
    form-background: $brand-white,
    form-border: $gray-base,
    form-placeholder: $gray-base,
    icon-color: $brand-primary,
    icon-color-hover: $brand-secondary,
  ),
  soda-strategic: (
    color: #1f0047,
    font: #ffffff,
    accent: #1f0047,
    accent-font: #8863f8,
    btn-color: $brand-white,
    btn-background: #8863f8,
    btn-border: #8863f8,
    form-label: #1f0047,
    form-label-active: #8863f8,
    form-background: $brand-white,
    form-border: $gray-base,
    form-placeholder: $gray-base,
    icon-color: #8863f8,
    icon-color-hover: #1f0047,
  ),
  gray-lighter: (
    color: $gray-lighter,
    font: $brand-black,
    accent: $brand-secondary,
    accent-font: $brand-primary,
    btn-color: $brand-white,
    btn-background: $brand-primary,
    btn-border: $brand-primary,
    form-label: $gray-base,
    form-label-active: $gray-base,
    form-background: $brand-white,
    form-border: $gray-base,
    form-placeholder: $gray-base,
    icon-color: $brand-primary,
    icon-color-hover: $brand-secondary,
  ),
  // gray-light: (
    //   color: $gray-light,
    //   font: $gray-dark,
    //   accent: $gray-darker,
    //   accent-font: $gray-light,
    //   btn-color: $brand-black,
    //   btn-background: $gray-base,
    //   btn-border: $brand-black,
    //   form-label: $gray-base,
    //   form-label-active: $gray-base,
    //   form-background: $brand-white,
    //   form-border: $gray-base,
    //   icon-color: $brand-primary,
    //   icon-color-hover: $brand-secondary,
    // ),
    gray-base:
    (
      color: $gray-base,
      font: $brand-white,
      accent: $brand-black,
      accent-font: $gray-base,
      btn-color: $brand-black,
      btn-background: $gray-base,
      btn-border: $brand-black,
      form-label: $gray-base,
      form-label-active: $brand-secondary,
      form-background: $brand-white,
      form-border: $brand-white,
      form-placeholder: $brand-white,
      icon-color: $brand-primary,
      icon-color-hover: $brand-secondary,
    ),
  gray-dark: (
    color: $gray-dark,
    font: $gray-light,
    accent: $gray-lighter,
    accent-font: $gray-dark,
    btn-color: $gray-dark,
    btn-background: transparent,
    btn-border: $gray-dark,
    form-label: $gray-base,
    form-label-active: $brand-secondary,
    form-background: $brand-white,
    form-border: $gray-base,
    form-placeholder: $gray-base,
    icon-color: $brand-primary,
    icon-color-hover: $brand-secondary,
  ),
);
