// Pills
.pill {
  color: $brand-white;
  &.brand-primary {
    background-color: $pill-primary;
  }
  &.brand-secondary {
    background-color: $pill-secondary;
  }
  &.brand-tertiary {
    background-color: $pill-tertiary;
  }
  &.brand-quaternary {
    background-color: $pill-quaternary;
  }
}

/* Icon Color Mapping - START */
@each $color, $color-swatch in $theme-palette {
  /* #{$color} Icon Color Mapping - START */
  @each $key, $icon in $icons {
    .icon.#{$key}.#{$color} {
      background-image: svgToURI(
        quote($icon),
        quote(map-get($color-swatch, color))
      );
    }
  }
  /* #{$color} Icon Color Mapping - END */
}
/* Icon Color Mapping - END */

/*
  Extra Color Mapping
  - for variables that cant be adjusted on the fly (e.g. svg fill colors)
*/
@each $color, $color-swatch in $theme-palette {
  // btn minimal pseudo chevrons
  .btn.#{$color} {
    &.minimal {
      &:after {
        background-image: svgToURI(
          map-get($icons, chevron_right),
          quote(map-get($color-swatch, color))
        );
      }
    }
  }
  // Loaders //
  .#{$color} {
    &.footer__banner,
    &.section__banner.swoosh {
      background-image: svgToURI(
        map-get($icons, churchill_swoosh),
        quote(map-get($color-swatch, accent))
      );
    }
    &.btn {
      &.loading {
        &:after {
          background-image: svgToURI(
            map-get($icons, loader_dots),
            quote(map-get($color-swatch, btn-color))
          );
        }
        &.btn-no-bg {
          &:after {
            background-image: svgToURI(
              map-get($icons, loader_dots),
              quote(map-get($color-swatch, color))
            );
          }
        }
      }
    }
    &.loader {
      background-color: map-get($color-swatch, color) !important;
      .loader__img {
        &.dots {
          background-image: svgToURI(
            map-get($icons, loader_dots),
            quote(map-get($color-swatch, accent))
          );
        }
        &.puff {
          background-image: svgToURI(
            map-get($icons, loader_puff),
            quote(map-get($color-swatch, accent))
          );
        }
        // add new loader class types here
      }
    }
    // Footer menu contact icons
    .footer__menu__contact {
      .phone {
        &:before {
          background-image: svgToURI(
            map-get($icons, phone),
            quote(map-get($color-swatch, icon-color))
          );
        }
      }
      .email {
        &:before {
          background-image: svgToURI(
            map-get($icons, email),
            quote(map-get($color-swatch, icon-color))
          );
        }
      }
    }
    // Footer menu social icons
    .footer__menu__social {
      .icon {
        &.facebook {
          background-image: svgToURI(
            map-get($icons, facebook),
            quote(map-get($color-swatch, icon-color))
          );
          &:hover {
            background-image: svgToURI(
              map-get($icons, facebook),
              quote(map-get($color-swatch, icon-color-hover))
            );
          }
        }
        &.linkedin {
          background-image: svgToURI(
            map-get($icons, linkedin),
            quote(map-get($color-swatch, icon-color))
          );
          &:hover {
            background-image: svgToURI(
              map-get($icons, linkedin),
              quote(map-get($color-swatch, icon-color-hover))
            );
          }
        }
        &.instagram {
          background-image: svgToURI(
            map-get($icons, instagram),
            quote(map-get($color-swatch, icon-color))
          );
          &:hover {
            background-image: svgToURI(
              map-get($icons, instagram),
              quote(map-get($color-swatch, icon-color-hover))
            );
          }
        }
        &.twitter {
          background-image: svgToURI(
            map-get($icons, twitter),
            quote(map-get($color-swatch, icon-color))
          );
          &:hover {
            background-image: svgToURI(
              map-get($icons, twitter),
              quote(map-get($color-swatch, icon-color-hover))
            );
          }
        }
        &.youtube {
          background-image: svgToURI(
            map-get($icons, youtube),
            quote(map-get($color-swatch, icon-color))
          );
          &:hover {
            background-image: svgToURI(
              map-get($icons, youtube),
              quote(map-get($color-swatch, icon-color-hover))
            );
          }
        }
        &.soundcloud {
          background-image: svgToURI(
            map-get($icons, soundcloud),
            quote(map-get($color-swatch, icon-color))
          );
          &:hover {
            background-image: svgToURI(
              map-get($icons, soundcloud),
              quote(map-get($color-swatch, icon-color-hover))
            );
          }
        }

        &.website {
          background-image: svgToURI(
            map-get($icons, soundcloud),
            quote(map-get($color-swatch, icon-color))
          );
          &:hover {
            background-image: svgToURI(
              map-get($icons, soundcloud),
              quote(map-get($color-swatch, icon-color-hover))
            );
          }
        }
        // &.blog {
        //   background: ;
        // }
        // &.video {
        //   background: ;
        // }
        // &.other {
        //   background: ;
        // }
      }
    }
  }
}
