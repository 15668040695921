// --- Type --- //

// Font Imports //
@font-face {
  font-family: "Gibson";
  src: url("../../dist/fonts/gibson-regular.woff2");
  src: url("../../dist/fonts/gibson-regular.woff2") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gibson";
  src: url("../../dist/fonts/gibson-italic.woff2");
  src: url("../../dist/fonts/gibson-italic.woff2") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gibson";
  src: url("../../dist/fonts/gibson-medium.woff2");
  src: url("../../dist/fonts/gibson-medium.woff2") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Gibson";
  src: url("../../dist/fonts/gibson-semi-bold.woff2");
  src: url("../../dist/fonts/gibson-semi-bold.woff2") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Gibson";
  src: url("../../dist/fonts/gibson-bold.woff2");
  src: url("../../dist/fonts/gibson-bold.woff2") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Gibson";
  src: url("../../dist/fonts/gibson-heavy.woff2");
  src: url("../../dist/fonts/gibson-heavy.woff2") format("truetype");
  font-weight: 900;
  font-style: normal;
}

// Global Font Weight Classes //
.light {
  font-weight: $fw-light;
}
.regular {
  font-weight: $fw-regular;
}
.medium {
  font-weight: $fw-medium;
}
.semi-bold {
  font-weight: $fw-semi-bold;
}
.bold {
  font-weight: $fw-bold;
}
.black {
  font-weight: $fw-black;
}

// Global Font Offset //
.font-offset {
  span {
    position: relative;
    top: 0.1em;
    line-height: 0;
  }
}

// Breakpoint font sizes //
$breakpoints: (
  small: 0,
  medium: 768px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);

$header-sizes: (
  small: (
    "h1": 24px,
    "h2": 22px,
    "h3": 18px,
    "h4": 14px,
    "h5": 14px,
    "h6": 12px,
    "p": 12px,
    "figcaption": 10px,
    "ul": 12px,
    "li": 12px,
  ),
  medium: (
    "h1": 36px,
    "h2": 28px,
    "h3": 24px,
    "h4": 18px,
    "h5": 16px,
    "h6": 14px,
    "p": 14px,
    "figcaption": 12px,
    "ul": 14px,
    "li": 14px,
  ),
  large: (
    "h1": 48px,
    "h2": 40px,
    "h3": 32px,
    "h4": 20px,
    "h5": 18px,
    "h6": 16px,
    "p": 16px,
    "figcaption": 14px,
    "ul": 16px,
    "li": 16px,
  ),
);

h1,
h2,
h3 {
  font-weight: $fw-medium;
}
p {
  line-height: rem(24px);
}
figcaption {
  line-height: rem(16px);
  color: var(--theme-font);
}

// Letter Spacing //
$letter-spacing-medium: 0.7px;
$letter-spacing-default: 1px;

// Font size change //

@each $size, $headers in $header-sizes {
  $breakpoint: map-get($breakpoints, $size);
  @media (min-width: $breakpoint) {
    @each $header, $font-size in $headers {
      #{$header} {
        font-size: $font-size;
      }
    }
  }
}
